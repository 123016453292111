@media(max-height:900px) {
    #landing {
        height: auto !important;
        position: relative;
        max-height:unset;
        .landing_pic{
            position: absolute;;
        }
        .fazes {
            width: 1200px;
            position: relative;
            top: unset;
            left: unset;
            transform: unset !important;;
            height: 100%;
            display: flex;
            justify-content: center;
            margin: 0 auto;
            padding: 260px 0;
            padding-bottom: 215px;
        }
        .download {
            position: relative;
            bottom: 0;
        }
        .heading-top h1 {
            font-size: 44px;
            line-height: 48px;
        }
        .heading-top h2{
            font-size:36px;
            line-height:30px;
        }
    }
    .menu {
        padding: 60px;
        overflow-y: auto;
    }
    .mt-60 {
        margin-top: 40px;
    }
    #landing #faze_landing{
        height:100% !important;
    }
    #faze_landing{
        height:auto !important;
        .faze_landing_wrap {
            padding-top: 300px;
        }
    }
}

@media(max-width:1650px) {
    #faze_landing .faze_landing_wrap > :not(.previous_chapter):not(.next_chapter){
        width:930px;
    }
    #faze_landing h1 {
        font-size: 120px;
        line-height: 120px;
        transform: rotate(-90deg) translateY(103px) translateX(-90px);
    }
    #faze_landing .faze_landing_underheading, #faze_landing .faze_landing_underheading a {
        font-size: 30px;
        line-height: 32px;
    }
    #faze_landing .faze_landing_wrap {
        padding-top: 100px;
    }
    #faze_landing{
        min-height:100vh;
    }
    #landing #faze_landing .faze_landing_wrap {
        padding-top: 190px;
    }
    .next_chapter, .previous_chapter{
        width:130px;
    }
    .one_button {
        img{
            width:30px;
        }
        p{
            font-size:15px !important;
            line-height:16px !important;
        }
    }
    #landing .bottom_buttons .one_button .download a{
        transform:translate3d(30%, 130%, 0) scale(0.5);
    }
    #landing .disclamer p {
        max-width:80%;
    }
}

@media(max-width:1440px) {
    .content > * {
        width: 795px;
    }
}

@media (max-width:1366px) {
    #menu_items button .dropdown_menu_content li a {
        font-size: 18px;
        line-height: 18px;
    }
    .menu {
        padding: 60px 25px;
    }
    #landing .logos img {
        width: 240px;
    }
    #landing .bottom_buttons .one_button p{
        font-size:16px;
    }
    .one_button img {
        width:30px;
    }
    .previous_chapter, .next_chapter{
        width:165px;
    }
    .custom_width_table {
        width: 1550px;
        margin-left: 0;
    }
    .custom_tables {
        overflow-x:auto;
        overflow-y:hidden;
    }

    #faze_landing .faze_landing_wrap{
        padding-top:0;
    }
    #faze_landing .faze_landing_wrap > *:not(.previous_chapter):not(.next_chapter) {
        margin-left: auto;
        margin-right: auto;
        width: 900px;
        h1{
    font-size: 95px;
    line-height: 95px;
    transform: rotate(-90deg) translateY(117px) translateX(-106px);
        }
    }

    #landing .fazes{
        width:1024px;
    }
    #landing .fazes .one_faze .faze_picture{
        height:200px;
    }
    .one_faze {
        .main-text {
            font-size: 16px;
            line-height: 20px;
        }
    }
    #landing .fazes .one_faze{
        height:350px;
    }
    #landing .heading-top h1 {
        font-size: 38px;
        line-height: 38px;
        padding: 10px 20px;
    }
    #landing .heading-top h2 {
        font-size: 33px;
        line-height: 27px;
    }
    .menu {
        width: 70%;
    }
    #landing .bottom_buttons{
        width:48%;
    }
    #landing .logos {
        position: absolute;
        bottom: 60px;
        left: 0;
        width: 95%;
        justify-content: space-between;
        display: flex;
        right: 0;
        margin: 0 auto;
    }
    #landing .fazes{
        padding-bottom:190px;
    }
    #faze_landing .faze_landing_wrap{
        width:90%;
    }
    #landing .bottom_buttons{
        z-index: 10;
    }
    #landing .fazes {
        width: 95%;
    }
    #landing .disclamer p {
        max-width: 90%;
        color: white;
        text-align: center;
        font-size: 12px;
    }
}

@media(max-width:1280px) {
    .next_chapter{
        background-color:$primary-color;
        bottom:0;
        right:0 !important;
        top: unset !important;
        width:50vw;
        height:auto;
        z-index:100;
        padding:15px 0;
        br{
            display:none;
        }
        a{
            color:white;
        }
    }
    .previous_chapter{
        background-color:$primary-color;
        bottom:0;
        left:0 !important;
        top: unset !important;
        width:50vw;
        height:auto;
        z-index:100;
        padding:15px 0;
        border-right:1px solid white;
        br{
            display:none;
        }
        a{
            color:white;
        }
    }
}

@media(max-width:1200px) {
    #landing .logos img {
        width: 200px;
    }
    #landing .heading-top h1 {
        font-size: 31px;
        line-height: 31px;
        padding: 10px 15px;
    }
    #menu_items button, #menu_items a{
        font-size:21px;
    }
    #landing .logos img {
        width: 200px;
    }
}

@media (max-width:1080px) {
    .one_button img {
        display:none;
    }
    .one_button p {
        margin-left: 0 !important;
    }
    #landing .bottom_buttons {
        width: 52%;
    }
    .fazes {
        & > .d-flex {
            width:100%;
        }
    }
    .footer{
        height:auto;
        margin-bottom:50px;
        .flex-footer{
            padding:40px 0;
            margin:0 auto;
            width: 795px;
        }
        .logos{
            img{
                height:80px;
            }
        }
        .copy p{
            font-size:12px;
            a{
                font-size:12px;
            }
        }
        .flex-footer {
        
            & > div {
                flex-basis:45%;
                &:nth-child(1) {
                    order: 1;
                }
                &:nth-child(2) {
                    margin-top:30px;
                    order: 3;
                    a,span{
                        text-align: left;
                    }
                }
                &:nth-child(3) {
                    margin-top:30px;
                    order: 4;
                    p {
                        text-align: right;
                    }
                }
                &:nth-child(4) {
                    order:2
                }
            }
        }
    }
    .content {
        width: 90%;
        margin: 100px auto;
    }
    #landing .bottom_buttons .one_button:hover .download a:first-child {
        transform: scale(1) translate3d(-50%, -20%, 0);
    }
    #landing .bottom_buttons .one_button:hover .download a:last-child {
        transform: scale(1) translate3d(55%, -20%, 0);
    }
    #landing .fazes .slick-slider .slick-list{
        overflow:hidden;
    }
    #landing .fazes .one_faze .card {
        box-shadow: 5px 5px 30px -9px rgba(0, 0, 0, 0.16);
    }
    .menu {
        width: 85%;
    }
    #menu_items button .dropdown_menu_content li {
        padding-bottom: 4px;
        font-size: 17px;
    }
    #faze_landing .faze_landing_wrap h1 {
        font-size: 120px;
        line-height: 121px;
        transform: rotate(-90deg) translateX(-85px) translateY(78px);
    }
    #menu_items button, #menu_items a {
        margin-right: 19px;
        &:last-child{
            margin-right:0;
        }
    }
    .content{
        width:90%;
    }
    #landing .disclamer p {
        max-width: 100%;
    }
}

@media (max-width:1024px) {
    #menu_items{
        display:none;
    }
    .responsive_menu{
        display:flex;
        justify-content: space-between;
        padding:15px 30px;
        #menu_items{
            display:block !important;
            button, a{
                margin-top:0;
                color:white;
                font-size:17px;
                &:first-child{
                    z-index: 1;
                    position:relative;
                }
            }
        }
        img{
            width:20px;
            height:auto;
            cursor:pointer;
        }
    }
    #menu_items button .dropdown_menu_content{
        width:100%;
        height:100vh;
        position:fixed;
        top:0;
    }
    .menu {
        width: 100%;
        padding:20px 0;
        .menu_content{
            padding:0 25px;
        }
        h3{
            position:fixed;
            bottom:0;
            left:0;
            width:100%;
            border-bottom:0;
            background-color:#4FA7DE;
            padding:15px 25px;
            color:white;
            font-size:20px;
        }
        .close_menu{
            bottom: 12px;
            top: unset;
            z-index: 10;
            width: 26px;
            right: 23px;
        }
    }

    .dropdown_menu_content{
        right:0;
        top:0;
        transform:translate3d(100%,0,0);
        opacity:1 !important;
        transition: all .5s ease-in;
        &.show_dropdown{
            transform:translate3d(0,0,0) !important;
        }
    }

    .additional_menu {
        height:auto;
        min-height:100%;
    }
    
    .responsive_close{
        display:flex;
        justify-content: space-between;
        width:100%;
        position:fixed;
        bottom:0;
        left:0;
        position:fixed;
        z-index: 105;
        padding-bottom:unset !important;
        padding:15px 25px !important;
        background-color:#4FA7DE;
        img{
            width:26px;
        }
        h3{
            color:white;
            font-family: $secondary-font;
            font-weight:400px;
            line-height:25px;
            font-size:20px;
        }
    }
    .additional_menu{
        display:block;
        padding:25px;
    }
    #landing .logos{
        display:none;
    }
    #landing .bottom_buttons{
        display:none;
    }
    #landing .fazes {
        padding-bottom: 145px;
    }
    .fazes .download{
        display:none !important;
    }
    .list_name_wrap{
        flex-direction: column-reverse;
        & > div {
            max-width:100%;
            flex-basis:100%;
        }
    }
    .top_nav_bar p:first-child{
        flex-basis:60%;
        max-width:60%;
    }
    #faze_landing .faze_landing_wrap  {
        margin-top:0;
        h1{
            font-size: 50px;
            line-height: 50px;
            transform: rotate(0deg) translateX(0) translateY(0);
        }
        br{
            display:none;
        }
        .faze_landing_underheading, .faze_landing_underheading a{
            font-size:25px;
            line-height:29px;
        }
    }
    #landing .fazes .slick-slider {
        height: 450px;
    }
    #landing .fazes .one_faze {
        height: 100%;
    }
    #landing .fazes .one_faze .faze_picture {
        height: 310px !important;
    }
    #landing .fazes .slick-slider .slick-track {
        height: 90%;
    }
    #landing .fazes .slick-slider .slick-list {
        overflow: hidden;
        display: flex;
        align-items: center;
    }
    #landing .disclamer{
        bottom:unset;
        top:0;
    }
    #landing .heading-top{
        top:90px;
    }
}

@media (max-width:750px) {
    .menu .menu_content {
        padding: 0 25px;
        overflow-y: auto;
        height: 100%;
    }
    .copy{
        display:none;
    }
    #landing .disclamer p{
        font-size:10px;
    }
    
}

@media (max-width:500px) {
    .diagram_pic img {
        width:100%;
    }
    .custom_table-no-wrap{

        .table {
            width:1200px;
            & > .d-flex {
                width:1200px;
                flex-wrap:nowrap;
                & > div {
                    flex: 1;
                }
            }
        }

    }
    .steps_table{
        display:none !important;
    }
    .custom_tables.zero_faze {
        width:1200px; 
        & > .d-flex {
            display:block !important;
            & > .d-flex {
                flex-wrap:nowrap;
                & > .d-flex:first-child {
                    flex-wrap:nowrap;
                }
            }
        }
    }
    #landing .fazes .slick-slider .slick-track .one_faze {
        flex-basis: 100% !important;
        max-width: 100% !important;
    }
    #landing .fazes .slick-slider {
        height: 540px;
    }
    #landing .fazes .one_faze .faze_picture {
        height: 350px !important;
    }
    #landing .fazes .one_faze h2, #landing .fazes .one_faze .card .back .main-text {
        padding-left: 25px;
        padding-right: 25px;
    }
    .fazes .slick-list{
        padding-left:0 !important;
    }
    .one_faze{
        &:after{
            width:auto;
            content:'Uvod u grant šeme';
            height:100%;
            width:50px;
            position: absolute;
            top:0;
            left:0;
            background-color:#FCC21A;
            transform:translate3d(-100%,0,0);
            writing-mode: vertical-rl;
            text-align:center;
            display:flex;
            justify-content: center;
            align-items: center;
            color:white;
            font-family:$secondary-font;
            font-size: 17px;
            letter-spacing: 0.5px;
        }
    }

    .fazes .slick-slide{
        &:nth-child(2) {
            .one_faze{
                &:after {
                    content: 'Konceptualizacija šeme'
                }
            }
        }
        &:nth-child(3) {
            .one_faze{
                &:after {
                    content: 'Izrada šeme'
                }
            }
        }
        &:nth-child(4) {
            .one_faze{
                &:after {
                    content: 'Razrada dokumenata u okviru šeme'
                }
            }
        }
    }

    .fazes .slick-slide:nth-child(2){
        .one_faze{
            &:after {
                background-color:#E98553;
            }
        }
    }

    .fazes .slick-slide:nth-child(3){
        .one_faze{
            &:after {
                background-color:#A5DFF9;
            }
        }
    }

    .fazes .slick-slide:nth-child(4){
        .one_faze{
            &:after {
                background-color:#6BB4E4;
            }
        }
    }

    .slick-dots{
        display:flex !important;
        .slick-active{
            transform: scaleY(1.5);
        }
        li{
            height:11px;
            transition:all .2s ease;
            flex: 1;
            width:unset;
            button{
                &:before{
                    width:100%;
                    height:100%;
                    background-color:#FCC21A;
                }
            }
            &:nth-child(2) {
                button{
                    &:before{
                        background-color:#E98553;
                    }
                }
            }
            &:nth-child(3) {
                button{
                    &:before{
                        background-color:#A5DFF9;
                    }
                }
            }
            &:nth-child(4) {
                button{
                    &:before{
                        background-color:#6BB4E4;
                    }
                }
            }
        }
    }

    .menu{
        padding: 30px 0;
        padding: 30px 0;
        z-index: 10000;
    }

    .download a{
        margin-left:0 !important;
    }

    .additional_menu a {
        margin-top: 25px;
        font-size: 19px;
        line-height: 25px;
        width: 100%;
    }

    .menu_content .d-flex-m-5_5:last-child .menu_list{
        margin-bottom:65px;
    }

    #menu_items button .dropdown_menu_content li a {
        font-size: 19px;
    }

    .additional_menu .additional_menu_images img {
        height: 80px;
        &.undp {
            margin-left:30px;
        }
    }
    

    .d-flex-m-5_5:first-child .mt-60:first-child{
        margin-top:0;
    }

    .fazes .slick-center{
        margin-right: 90px;
    }
    #landing .fazes .slick-slider .slick-track .one_faze {
        flex-basis: 80% !important;
        max-width: 80% !important;
    }

    .top_nav_bar {
        #menu_items{
            display:none !important;
        }
    }

    .top_nav_bar p:first-child {
        flex-basis: 100%;
        max-width: 100%;
    }

    #landing{
        height:auto !important;
    }
    #landing .fazes {
        position: relative;
        top: 0;
        left: 0;
        transform: translate3d(0%, 0%, 0);
    }
    #landing .heading-top{
        top:30px;
    }
    #landing .fazes .slick-slider {
        height: 445px;
    }
    .responsive_menu{
        padding:15px;
    }
    #landing .fazes {
        padding-bottom: 100px;
        padding-top: 180px;
    }
    .top_nav_bar p, .top_nav_bar a{
        font-size:19px;
    }
    #faze_landing .faze_landing_wrap h1 {
        font-size: 45px;
        line-height: 45px;
    }
    .faze_landing_wrap > .d-flex-jcsb {
        justify-content: flex-end;
    }
    #faze_landing .faze_landing_wrap .faze_landing_underheading, #faze_landing .faze_landing_wrap .faze_landing_underheading a {
        font-size: 20px;
        line-height: 20px;
    }
    .faze_one_animate {
        animation: unset !important;
    }
    .faze_one_animate_2 {
        animation: unset !important;
    }
    #faze_landing .list_name_wrap {
        min-height: 250px;
    }
    #faze_landing{
        align-items:flex-start;
    }
    #faze_landing .faze_landing_wrap {
        margin-top: 100px;
    }
    .top_nav_bar img {
        margin-right: 15px;
        width: 25px;
    }
    .additional_menu a {
        margin-top: 20px;
        font-size: 14px;
        line-height: 15px;
    }
    .additional_menu h3{
        margin-top:50px;
    }
    .additional_menu{
        overflow-y:auto;
    }
    .previous_chapter a, .next_chapter a {
        font-size: 17px;
        line-height: 17px;
    }
    #faze_landing .faze_landing_wrap > *:not(.previous_chapter):not(.next_chapter){
        width:90%;
    }
    #faze_landing .faze_landing_wrap > *:not(.previous_chapter):not(.next_chapter) h1 {
        font-size: 55px;
        line-height: 55px;
        transform: unset;
    }
    #landing #faze_landing .faze_landing_wrap {
        padding-top: 0px;
    }
    .content > * {
        width: 100%;
    }
    .content {
        width: 90%;
    }
    .yellow_box {
        margin-bottom: 25px;
        padding: 30px 29px;
    }
    .content_heading img {
        width: 43px;
        margin-right: 15px;
    }
    .content .yellow_overlay_text:first-child {
        top: 49px;
    }
    .content_text, .body {
        font-size: 17px !important;
        line-height: 21px !important;
    }
    .toolpit_wrap .toolpit .main-text {
        font-size: 14px !important;
        line-height: 17px !important;
    }
    .footer .flex-footer{
        width:90%;
    }
    .footer .flex-footer > div {
        flex-basis: 100%;
    }
    .footer .flex-footer > div:nth-child(2) a, .footer .flex-footer > div:nth-child(2) span {
        text-align: center;
    }
    .footer .flex-footer > div:nth-child(3) p {
        text-align: center;
    }
    .footer .flex-footer > div:nth-child(1) {
        order: 1;
        display: flex;
        justify-content: center;
    }
    .copy {
        display: block;
    }
    .footer .flex-footer > div:nth-child(4) {
        order: 5;
        margin-top:30px;
        p{
            text-align: center;
        }
    }
    .pageFazeLanding {
        padding: 15px 0;
    }
    .content_heading {
        font-size: 20px;
        line-height: 25px;
        padding: 8px;
    }
        .next_chapter{
            bottom:54px;
            border-bottom:1px solid white;
        }
        .previous_chapter {
            bottom:54px;
            border-bottom:1px solid white;
        }
        .additional_menu{
            z-index:1000;
        }
        .responsive_menu{
            z-index:1000;
        }
        .footer {
            height: auto;
            padding-bottom: 25px;
        }
        #table070 , #table072 , #table074 , #table085 , #table088 ,#table089, #table093 ,#table092 ,#table099  {
            width:700px;
        }
        .content_heading_big {
            font-size: 27px;
            line-height: 30px;
        }
        #landing > .copy {
            display:none;
        }

        #landing #faze_landing{
            display:none !important;
        }
        #landing .landing_pic {
            width: 219%;
            height: 100%;
            position: absolute;
            top: 0;
            left: -54%;
            overflow: hidden;
        }
        .custom_table_1.custom_tables {
            & > .d-flex {
                flex-wrap: nowrap;
                width: 1200px;
            }
        }
        .custom_tables {
            .d-flex-s-12 > .d-flex-s-2 {
                 p {
                     width: 100%;
                     border-bottom:1px solid white;
                 }
            }
        }
        #landing .heading-top {
            top: 110px;
        }
        #landing .fazes {
            padding-bottom: 100px;
            padding-top: 280px;
        }
}

@media (max-width:390px) {
    #landing .heading-top h1 {
        font-size: 27px;
        line-height: 28px;
        padding: 10px 15px;
    }
}

@media (max-width:1280px) and (min-height:900px) {
    #landing .download {
        position: relative;
        bottom: 0;
        margin-top: 55px;
    }
    .fazes {
        padding: 260px 0;
        padding-bottom: 215px;
    }
    .download {
        position: relative;
        bottom: 0;
        margin-top: 55px;
    }
}